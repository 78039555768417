
import { REACT_APP_URL } from '@joinlean/enviroments'

export const HttpFormService = async (stateForm) => {
	const body = stateForm.form;
	console.log(`${REACT_APP_URL}/form`);

	return fetch(`${REACT_APP_URL}/form`, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json'
		},
		body: JSON.stringify(body)
	});
};
