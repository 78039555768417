export const useScrollDOM = () => {
	const defineElement = ({ className, id, element }) => {
		let pageElement;

		if (className) {
			pageElement = document.getElementsByClassName(className)[0];
		} else if (id) {
			pageElement = document.getElementById(id);
		} else if (element) {
			pageElement = document.getElementsByName(element)[0];
		}

		return pageElement;
	};

	const scrollTo = ({ className, id, element }) => {
		const pageElement = defineElement({
			className,
			id,
			element
		});

		if (pageElement) {
			pageElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
				// inline: 'nearest'
			});
		}
	};

	return { scrollTo };
};
