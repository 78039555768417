import { useLayoutEffect, useEffect, useState } from 'react';

import { usePageReloadCounter } from './usePageReloadCounter';

export const useAnimation = ({
	isVisible = true,
	animationStart = 'animate__fadeIn',
	animationEnd = 'animate__fadeOut',
	endClass = `d-none`,
	duration = 800
}) => {
	const { increasePageCounter, isAfterFirstLoad } = usePageReloadCounter();
	useEffect(
		() => {
			increasePageCounter();
		},
		[ increasePageCounter ]
	);

	const [ styles, setStyles ] = useState([]);

	const updateStyles = () => {
		setStyles([ 'animate__animated', isVisible ? `${animationStart}` : `${animationEnd}` ]);
	};

	const updateByFirstLoad = () => {
		updateStyles();

		let timer;
		if (isAfterFirstLoad) {
			timer = setTimeout(() => {
				// updateStyles();
				setStyles((e) => {
					return [ ...e, isVisible ? '' : endClass ];
				});
			}, isVisible === null ? 0 : duration);
		} else {
			if (!isVisible) {
				setStyles([ endClass ]);
			}
		}

		return () => {
			clearInterval(timer);
		};
	};

	useLayoutEffect(
		() => {
			updateByFirstLoad();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ isVisible ]
	);

	return [ styles.filter((element) => element !== '').join(' ') ];
};
