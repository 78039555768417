import React from 'react';

export function SVGExistingProject(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M817.223 235.902v664.593H182.828V67.79h449.085"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
			/>
			<path
				d="M631.913 235.901h185.31L631.912 67.79v168.111z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
				strokeLinecap="butt"
			/>
			<path
				d="M249.623 247.045V469.15h167.574V296.92l-37.903-49.875H249.623zM528.916 327.03H749.69M749.69 390.86H691.17M639.302 390.86H528.916M528.916 454.7h95.758M671.222 454.7h78.468"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
				strokeLinecap="butt"
				strokeLinejoin="miter"
				d="M739.414 538.53H260.627v274.86h478.787z"
			/>
			<path
				d="M260.633 726.017l106.017-78.531 94.05 78.53 147.055-142.338 131.666 109.945"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
				strokeLinecap="butt"
				strokeLinejoin="miter"
			/>
			<ellipse
				cx={-19.007}
				rx={19.565}
				ry={19.007}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={18.83}
				strokeLinecap="butt"
				strokeLinejoin="miter"
				transform="matrix(0 -1.6994 -1.6994 0 449.498 575.61)"
			/>
			<path
				d="M530.675 658.29h166.424"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
				strokeLinecap="butt"
				strokeLinejoin="miter"
			/>
		</svg>
	);
}
