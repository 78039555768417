import React, { useContext } from 'react';

import { LanguageContext, ScreenContext } from '../../contexts';
import { Title } from '../../components';

import './index.scss';

export const UsSection = () => {
	const { language } = useContext(LanguageContext);
	const { type } = useContext(ScreenContext);

	return (
		<section id="us" className="bg-light">
			<Title
				a-join="animate__fadeInUp animate__fadeOutDown"
				className="container"
				title={
					{
						es: 'Un socio para los retos digitales de',
						en: 'A Partner for the Digital Challenges of'
					}[language]
				}
				accentTitle={
					{
						es: 'tu proyecto',
						en: 'Your Project'
					}[language]
				}
				text={
					{
						es:
							'Talento y soluciones tecnológicas para compañías de cualquier industria, agilizando la implementación de sus innovaciones y sumando valor en equipo.',
						en:
							'Talent and technological solutions for companies in any industry, speeding up the implementation of their innovations and adding value as a team.'
					}[language]
				}
			/>

			<div className="w-100 mb--3 ">
				<img className="w-100" src={`/assets/illustrations/effects/slogan-${type}.svg`} alt="" />
			</div>
		</section>
	);
};
