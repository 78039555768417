import React from 'react';

export function SVGTwoWeeks(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M747.556 582.895c-9.505-11.805-26.773-13.67-38.58-4.171l-70.737 32.323-57.38-117.723-59.347-35.053-130.327-2.823a41.564 41.564 0 00-35.08 17.155l-49.899 68.88c-7.97 11.003-5.492 26.389 5.533 34.331l.404.292c11.06 7.969 26.494 5.404 34.383-5.712l45.228-63.736 73.09 12.414L354.501 741.43l-106.953-48.565c-11.588-5.933-25.793-1.791-32.378 9.441l-3.727 6.358c-6.857 11.699-2.935 26.742 8.763 33.602l124.432 72.973c13.922 8.164 31.769 4.356 41.142-8.782 27.19-38.111 78.6-109.649 80.715-108.66l95.713 64.175.51 131.356s-.137 35.245 16.052 42.57a32.054 32.054 0 0013.24 2.865c12.247 0 23.951-7.032 29.329-18.917l12.58-160.296s.27-34.937-15.68-42.397L514.623 637.11l39.663-57.96 54.596 85.129c9.694 11.05 26.377 12.494 37.827 3.278l96.677-46.082c11.804-9.503 13.671-26.774 4.171-38.58zm-221.96-171.222c0-32.397 26.263-58.66 58.66-58.66 32.4 0 58.661 26.263 58.661 58.66 0 32.398-26.262 58.662-58.66 58.662-32.398 0-58.66-26.264-58.66-58.662z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={35.726456500000005}
			/>
			<path
				d="M424.919 393.11H202.267M263.882 493.93H156.057M313.593 629.76H108.446M241.476 830.01H128.05M391.311 919.63H108.446M151.856 734.79H46.832"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={35.726456500000005}
				strokeLinecap="round"
			/>
			<path
				d="M862.701 305.631c-22.713-11.605-50.991-4.372-65.366 16.698-10.977 16.086-11.504 35.898-3.481 51.876 2.276 4.534 1.936 9.947-.68 14.294l-5.403 8.98c-.602 1-.28 2.3.72 2.902l9.886 5.949 7.972 4.796 3.272 1.97 10.37 6.24c1 .603 2.3.28 2.903-.72l5.493-9.129c2.633-4.374 7.339-7.016 12.442-7.15 16.872-.442 33.168-9.214 42.516-24.748 15.185-25.234 6.04-58.322-20.644-71.958z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.265122700000003}
			/>
			<path
				d="M799.35 455.707l-32.725-19.694M775.804 420.761l32.724 19.694"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.265122700000003}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M788.67 449.28a6.633 6.633 0 01-11.366-6.84"
				fill="#fff"
				fillRule="nonzero"
				stroke="#ffbf10"
				strokeWidth={31.265122700000003}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M888.234 421.461l15.89 26.84M924.687 375.55l29.482 10.19M921.451 317.06l29.22-10.917M885.285 273.558l16.351-26.567M830.46 263.157l-4.88-30.807M774.847 286.039l-23.521-20.489M751.326 341.819l-31.172-1.107"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.265122700000003}
				strokeLinecap="round"
			/>
		</svg>
	);
}
