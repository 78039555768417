import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts';

import { FormButton } from '../../components/FormButton';
import { SVGFormWeb, SVGFormMobileApp, SVGFormServerDB, SVGFormWebsites, SVGFormOtherProjects } from './Svgs';

import './index.css';

export const TypeProjectSection = ({ stateForm, setStateForm }) => {
	const { language } = useContext(LanguageContext);

	const projectTypes = [
		{
			id: 'web',
			title: language === 'es' ? 'Web Apps' : 'Web Development',
			Svg: SVGFormWeb
		},
		{
			id: 'mobile_app',
			title: language === 'es' ? 'Apps Móviles' : 'Mobile App Development',
			Svg: SVGFormMobileApp
		},
		{
			id: 'server_databases',
			title: language === 'es' ? 'Servidores y bases de datos' : 'Server and databases',
			Svg: SVGFormServerDB
		},
		{
			id: 'websites',
			title: language === 'es' ? 'Página web' : 'Websites',
			Svg: SVGFormWebsites
		},
		{
			id: 'other',
			title: language === 'es' ? 'Otros projectos' : 'Other projects',
			Svg: SVGFormOtherProjects
		}
	];

	const handleClick = (type) => {
		setStateForm((state) => {
			return {
				...state,
				form: {
					...state.form,
					type: type.id
				}
			};
		});
	};

	return (
		<React.StrictMode>
			{projectTypes.map((type) => {
				return (
					<div key={type.id} className="col-unit-1 col-unit-sm-2 col-unit-md-3 col-unit-lg-5">
						<FormButton
							isActive={stateForm.form.type === type.id}
							Svg={type.Svg}
							icon={type.icon}
							iconActive={type.iconActive}
							text={type.title}
							onClick={() => handleClick(type)}
						/>
					</div>
				);
			})}
		</React.StrictMode>
	);
};
