import React from 'react';

export function SVGOneMonth(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M517.4 268.688c35.982 0 65.158 29.18 65.158 65.155 0 35.982-29.176 65.158-65.158 65.158-35.98 0-65.155-29.176-65.155-65.158 0-35.975 29.176-65.155 65.155-65.155zm55.61 185.992l54.307 93.402 74.476-28.726c15.367-5.94 32.64 1.702 38.58 17.067 5.937 15.365-1.705 32.642-17.068 38.58l-97.006 37.419c-13.746 6.055-30.142.923-37.845-12.376l-12.962-22.294v56.491l70.791 111.721a29.864 29.864 0 013.908 9.348l39.501 145.845c4.258 15.946-5.212 32.329-21.16 36.59-15.946 4.263-32.333-5.212-36.595-21.159l-38.561-142.373-60.533-95.541h-22.082l-33.874 107.478a29.88 29.88 0 01-3.371 7.246l-74.13 130.207c-8.135 14.332-26.353 19.355-40.686 11.221-9.244-5.246-14.609-14.693-15.076-24.578v-2.831a29.604 29.604 0 013.854-13.27l73.296-128.743 38.537-122.261V513.25l-36.297 23.511-20.003 104.447c1.55 16.4-10.488 30.952-26.889 32.505-16.396 1.547-30.948-10.49-32.498-26.891l18.337-121.819c-1.252-10.894 3.566-22.076 13.386-28.452l99.275-65.061c37.315-24.458 76.422-14.588 98.387 23.19z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={36.3603218}
			/>
			<path
				d="M330.981 459.84H178.61M292.397 609.45H111.19M351.39 761.16H208.11"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={36.3603218}
				strokeLinecap="round"
			/>
			<path
				d="M160.94 120.807c1.742-26.963 23.013-50.762 47.993-59.51 13.094-4.584 28.788-5.337 41.057 2.028 12.03 7.222 18.515 23.41 10.955 36.086l2.621 1.532c11.692-21.724 41.824-23.17 57.498-5.193 17.593 20.18 10.339 50.033-10.395 64.845-.652.465-1.092 1.412-.567 2.164 16.359 23.455 12.542 58.575-9.37 77.353-22.573 19.346-59.356 16.36-79.49-4.975-.777-.823-2.41-.47-2.64.697-3.55 18.07-14.925 34.291-31.756 42.26-15.108 7.154-33.625 7.643-48.908.722-29.994-13.583-38.785-56.321-15.594-80.173 1.206-1.241-.025-3.131-1.627-2.795-15.324 3.222-30.02-7.332-36.38-20.83-6.091-12.922-4.872-28.741 1.632-41.257 14.04-27.018 53.027-33.455 74.971-12.954"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={36.3603218}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx={-18.405}
				r={18.405}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.01}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 -1.34618 -1.34618 0 290.91 271.97)"
			/>
			<circle
				cx={-13.015}
				cy={0.001}
				r={13.015}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.01}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 -1.34618 -1.34618 0 344.67 332.98)"
			/>
			<path
				d="M840.06 120.807c-1.74-26.963-23.013-50.762-47.991-59.51-13.096-4.584-28.79-5.337-41.057 2.028-12.033 7.222-18.516 23.41-10.957 36.086l-2.62 1.532c-11.693-21.724-41.825-23.17-57.5-5.193-17.592 20.18-10.338 50.033 10.396 64.845.653.465 1.093 1.412.568 2.164-16.359 23.455-12.542 58.575 9.371 77.353 22.573 19.346 59.356 16.36 79.488-4.975.777-.823 2.41-.47 2.64.697 3.55 18.07 14.925 34.291 31.757 42.26 15.107 7.154 33.624 7.643 48.909.722 29.992-13.583 38.784-56.321 15.594-80.173-1.208-1.241.023-3.131 1.626-2.795 15.325 3.222 30.018-7.332 36.379-20.83 6.091-12.922 4.872-28.741-1.632-41.257-14.039-27.018-53.026-33.455-74.971-12.954"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={36.3603218}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx={18.405}
				r={18.405}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.01}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 1.34618 1.34618 0 710.09 271.97)"
			/>
			<ellipse
				cx={13.014}
				cy={0.001}
				rx={13.015}
				ry={13.014}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.01}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 1.34618 1.34618 0 656.33 332.98)"
			/>
		</svg>
	);
}
