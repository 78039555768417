import { useState, useCallback } from 'react';

export const usePageReloadCounter = () => {
    const [pageReloadState, setPageReloadState] = useState({
        counter: 0,
        isAfterFirstLoad: false,
    });

    const increasePageCounter = useCallback(() => {
        return setPageReloadState((e) => {
            const counter = e.counter + 1;
            return {
                counter,
                isAfterFirstLoad: counter > 0,
            }
        });
    }, []);

    return {
        pageReloadCounter: pageReloadState.counter,
        increasePageCounter,
        isAfterFirstLoad: pageReloadState.isAfterFirstLoad,
    }
}
