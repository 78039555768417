import React from 'react';

import './index.scss';

export const FormButton = ({ isActive, iconActive, Svg, icon, text, onClick }) => {
	return (
		<button
			onClick={onClick}
			className={[ 'form__body__button h-100 p-3', isActive && 'is--active', isActive && '' ].join(' ')}
		>
			{Svg ? <Svg /> : <img src={icon} alt="" />}
			<p>{text}</p>
		</button>
	);
};
