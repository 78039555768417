const assets = {
	brandingIcon: '/assets/branding/icon.svg',
	brandingIconDark: '/assets/branding/icon-dark.svg',
	brandingLogoDark: '/assets/branding/logo-dark.svg',
	brandingLogoLight: '/assets/branding/logo-light.svg',
	iconsMenu: '/assets/icons/menu.svg',
	iconsArrowLeft: '/assets/icons/arrow_left.svg',
	iconsArrowRight: '/assets/icons/arrow_right.svg'
};

export default assets;