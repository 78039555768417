import React, { useContext, useState } from 'react';

import { Title } from '../../components';
import { LanguageContext } from '../../contexts';

import { ContactFormHeader, ContactFormBody, ContactFormFooter } from './components';

import './index.css';

export const ContactSection = () => {
	const { language } = useContext(LanguageContext);

	const [ stateForm, setStateForm ] = useState({
		sectionSelected: 0,
		form: {},
		sections: [
			{
				title: {
					en: 'Tell us what you need to build?',
					es: '¿Cuéntanos que quieres crear?'
				}
			},
			{
				title: {
					en: 'Is it a new project?',
					es: '¿Es un proyecto nuevo?'
				}
			},
			{
				title: {
					en: 'When do you need it to be complete?',
					es: '¿Qué tan rápido lo necesitas?'
				}
			},
			{
				title: {
					en: 'Thanks, We just need a few details about you:',
					es: 'Gracias, solo necesitamos unos detalles de ti:'
				}
			}
		]
	});

	const isFirstOne = stateForm.sectionSelected === 0;
	const isLastOne = stateForm.sections.length - 1 === stateForm.sectionSelected;
	const isAfterLastOne = stateForm.sections.length === stateForm.sectionSelected;

	return (
		<section id="contact" className="d-flex flex-column gradient-primary pb-5">
			<div className="container">
				<Title
					a-join="animate__fadeInUp animate__fadeOutDown"
					title={{ es: 'Queremos', en: 'We want' }[language]}
					accentTitle={
						{
							es: 'Conocerte',
							en: 'to meet you'
						}[language]
					}
					text={
						{
							es:
								'Inicia la transformación digital de tu empresa para reducir costos y aumentar las ventas hoy mismo. Conversemos y descubramos cómo podemos ayudarte.',
							en:
								"Start your company's digital transformation to reduce costs and increase sales today. Let's talk and find out how we can help you."
						}[language]
					}
					isLight={true}
				/>
			</div>
			<div id="form-contact" className="container-fluid bg-light">
				<div className="container py-5" a-join="animate__fadeIn animate__fadeOut">
					<div className="row g-4">
						<ContactFormHeader
							stateForm={stateForm}
							setStateForm={setStateForm}
							isAfterLastOne={isAfterLastOne}
						/>
						<ContactFormBody stateForm={stateForm} setStateForm={setStateForm} />
						<ContactFormFooter
							stateForm={stateForm}
							setStateForm={setStateForm}
							isFirstOne={isFirstOne}
							isLastOne={isLastOne}
							isAfterLastOne={isAfterLastOne}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
