import React from 'react';

export function SVGFormWeb(props) {
	return (
		<svg
			viewBox="0 0 1000 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M829.65 678.463H175.559c-12.45 0-22.543-10.092-22.543-22.543V236.862c0-12.45 10.093-22.542 22.543-22.542H829.65c12.45 0 22.543 10.092 22.543 22.542V655.92c0 12.45-10.093 22.543-22.543 22.543zM977.474 799.111c-4.88 3.32-7.189 5.821-7.96 6.747H30.597c-.77-.926-3.208 3.32-8.088 0-5.791 6.199 2.814 9.346 2.814 9.346 3.616 4.673 12.164 9.346 31.89 9.346h885.684c19.728 0 28.275-4.673 31.892-9.346 0 0 8.476-9.894 2.685-16.093z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={32.0038498}
			/>
			<path
				d="M977.474 799.115L849.44 686.603a32.714 32.714 0 00-21.594-8.14h-655.58a32.717 32.717 0 00-21.595 8.14L22.638 799.115c-.639 2.572 1.202 4.258 3.201 5.302 1.904.994 4.044 1.445 6.192 1.445h936.05c2.148 0 4.289-.451 6.191-1.445 2.001-1.044 3.84-2.73 3.202-5.302z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={32.0038498}
			/>
			<path
				d="M373.652 767.163l13.647-34.4a1.902 1.902 0 011.772-1.201l228.11.334a1.903 1.903 0 011.782 1.245l12.613 34.302A1.903 1.903 0 01629.79 770l-254.371-.233a1.902 1.902 0 01-1.766-2.605z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={21.995456800000003}
			/>
			<path
				d="M0 343.228h-459.262c-11.338 0-20.529-9.191-20.529-20.529V20.529C-479.791 9.191-470.6 0-459.262 0H0c11.338 0 20.528 9.191 20.528 20.529v302.17c0 11.338-9.19 20.529-20.528 20.529z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={19.61}
				transform="matrix(1.15039 0 0 1.09245 767.066 257.14)"
			/>
			<path
				d="M0-37.152h-466.725c-9.277 0-16.797 7.52-16.797 16.797V0H16.797v-20.355c0-9.277-7.52-16.797-16.797-16.797z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={14.98}
				transform="matrix(1.15039 0 0 1.72998 771.358 321.413)"
			/>
			<ellipse
				cx={-7.772}
				rx={7.772}
				ry={7.773}
				fill="#fff"
				stroke="#ffbf10"
				strokeWidth={27.82}
				transform="matrix(0 -.51304 -.51304 0 730.185 284.332)"
			/>
			<circle
				cx={-7.772}
				r={7.772}
				fill="#fff"
				stroke="#ffbf10"
				strokeWidth={27.82}
				transform="matrix(0 -.51304 -.51304 0 699.415 284.333)"
			/>
			<circle
				cx={-7.772}
				r={7.772}
				fill="#fff"
				stroke="#ffbf10"
				strokeWidth={27.82}
				transform="matrix(0 -.51304 -.51304 0 668.645 284.333)"
			/>
			<g>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={18.0036035}
					d="M487.66 373.762H275.52V499.32h212.14zM318.716 535.683H275.52v43.196h43.196zM405.13 535.683h-43.196v43.196h43.196zM491.544 535.683h-43.196v43.196h43.196z"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 615.829h202.149v9.388H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -333.267)"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 591.505h202.149v9.387H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -268.863)"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 567.182h202.149v9.387H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -204.461)"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 542.858h202.149v9.387H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -140.059)"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 518.534h202.149v9.387H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -75.656)"
				/>
				<path
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.5}
					d="M501.732 495.307h202.149v9.387H501.732z"
					transform="matrix(-1.02817 0 0 1.15039 1250.04 -12.515)"
				/>
			</g>
		</svg>
	);
}
