import React, { useContext } from 'react';

import { useScrollDOM } from '../../../../hooks';
import { Assets } from '../../../../utils';
import { FormButtonContinue } from '../ButtonContinue';
import { HttpFormService } from '../../service';
import { LanguageContext } from '../../../../contexts';

import './index.css';

export const ContactFormFooter = ({ stateForm, setStateForm, isFirstOne, isLastOne, isAfterLastOne }) => {
	const { language } = useContext(LanguageContext);

	const { scrollTo } = useScrollDOM();

	const isDisabled = () => {
		const form = stateForm.form;

		if (stateForm.sectionSelected === 0 && form.type) {
			return false;
		} else if (stateForm.sectionSelected === 1 && form.status) {
			return false;
		} else if (stateForm.sectionSelected === 2 && form.time) {
			return false;
		} else if (stateForm.sectionSelected === 3) {
			if (!form.contact) return true;
			if (!form.contact.name) return true;

			const email = form.contact.email;
			let emailValidation = true;
			if (email) {
				emailValidation = !_validateEmail(email);
			}

			return emailValidation;
		}

		return true;
	};

	const handleNextSection = async () => {
		if (isDisabled()) return;

		if (stateForm.sectionSelected === 3) {
			await HttpFormService(stateForm);
		}

		scrollTo({
			className: 'form__form__header'
		});

		setStateForm((state) => {
			let sectionSelected = state.sectionSelected + 1;
			if (sectionSelected > 4) {
				sectionSelected = 4;
			}

			return {
				...state,
				sectionSelected: sectionSelected
			};
		});
	};

	const _validateEmail = (value) => {
		if (!value) return false;
		if (!/^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/.test(value)) return false;
		return true;
	};

	const handleBeforeSection = () => {
		setStateForm((state) => {
			let sectionSelected = state.sectionSelected - 1;
			if (sectionSelected < 0) {
				sectionSelected = 0;
			}

			return {
				...state,
				sectionSelected: sectionSelected
			};
		});
	};

	const getReturnButton = () => {
		const text = language === 'es' ? 'Regresar' : 'Return';

		return isFirstOne ? (
			<div />
		) : (
			<FormButtonContinue onClick={handleBeforeSection}>
				{
					<div className="d-flex flex-center">
						<img className="footer_button_img h-100 me-2" src={Assets.iconsArrowLeft} alt="" />
						<p className="d-flex flex-center-center mb-0">{text}</p>
					</div>
				}
			</FormButtonContinue>
		);
	};

	const getNextButton = () => {
		const text = isLastOne
			? language === 'es' ? 'Finalizar' : 'Finish'
			: language === 'es' ? 'Siguiente' : 'Next';
		const disable = isDisabled();

		return (
			<FormButtonContinue onClick={handleNextSection} disable={disable}>
				{
					<div className="d-flex flex-center-center">
						<p className="d-flex flex-center-center me-2 mb-0">{text}</p>
						<img className="footer_button_img h-100" src={Assets.iconsArrowRight} alt="" />
					</div>
				}
			</FormButtonContinue>
		);
	};

	return (
		<React.StrictMode>
			{!isAfterLastOne && (
				<div className="d-flex flex-between">
					{getReturnButton()}
					{getNextButton()}
				</div>
			)}
		</React.StrictMode>
	);
};
