import React from 'react';

import './index.scss';

export const FormButtonContinue = ({ children, onClick, disable }) => {
	return (
		<button className={[ 'form_footer_button text-light', disable && 'is--disable' ].join(' ')} onClick={onClick}>
			{children}
		</button>
	);
};
