import React from 'react';

import {
  HeaderSection,
  IntroductionSection,
  UsSection,
  ServicesSection,
  TechnologiesSection,
  MethodologySection,
  KeysSection,
  ContactSection,
  ClientsSection,
  FooterSection,
} from './sections';

import { LanguageManager, ScreenManager, ClickManager } from './contexts';

import './styles/index.scss';

const App = () => {
  return (
    <ScreenManager>
      <LanguageManager>
        <ClickManager>
          <HeaderSection />
          <IntroductionSection />
          <UsSection />
          <ServicesSection />
          <TechnologiesSection />
          <MethodologySection />
          <KeysSection />
          <ClientsSection />
          <ContactSection />
          <FooterSection />
        </ClickManager>
      </LanguageManager>
    </ScreenManager>
  );
};

export default App;
