import React, { useContext } from 'react';

import { Title } from '../../components';
import { LanguageContext, ScreenContext } from '../../contexts';

import './index.scss';

export const TechnologiesSection = () => {
	const { language } = useContext(LanguageContext);
	const { type } = useContext(ScreenContext);

	const technologies = [
		{ src: '/assets/images/android.png', alt: 'Android Brand' },
		{ src: '/assets/images/apple_ios.png', alt: 'Apple Brand' },
		{ src: '/assets/images/windows.png', alt: 'Windows Brand' },
		{ src: '/assets/images/huawei.png', alt: 'Huawei Brand' },
		{ src: '/assets/images/flutter.png', alt: 'Flutter Brand' },
		{ src: '/assets/images/angular.png', alt: 'Angular Brand' },
		{ src: '/assets/images/firebase.png', alt: 'React Brand' },
		{ src: '/assets/images/mongo_db.png', alt: 'NodeJS Brand' },
		{ src: '/assets/images/react.png', alt: 'Firebase Brand' },
		{ src: '/assets/images/my_sql.png', alt: 'MongoDB Brand' },
		{ src: '/assets/images/node_js.png', alt: 'MySQL Brand' },
		{ src: '/assets/images/postgre_sql.png', alt: 'PostgreSQL Brand' }
	];

	return (
		<section id="technologies" className="bg-light">
			<Title
				a-join="animate__fadeInUp animate__fadeOutDown"
				className="container pt-4 pt-md-5 pb-3 pb-md-4"
				title={
					{
						es: 'Tecnologías',
						en: 'Development'
					}[language]
				}
				accentTitle={
					{
						es: 'de desarrollo',
						en: 'Technologies'
					}[language]
				}
				text={
					{
						es:
							'Trabajamos con las tecnologías y los lenguajes de programación más extendidos del mercado. ¿No es lo que buscas? También nos adaptamos a las particularidades de tu proyecto.',
						en:
							'We work with the most extended technologies and programming languages in the market. Not what you are looking for? We also adapt to the particularities of your project.'
					}[language]
				}
				isLight={false}
			/>

			<div className="w-100 mb--3">
				<img className="h-100 w-100" src={`/assets/illustrations/effects/technologies-${type}.svg`} alt="" />
			</div>
			{/* w-100 d-flex overflow-scroll */}
			<div className="bg-third pt-3 pb-4 pb-md-5">
				{/* <div className="technologies_brands"> */}
				<div className="container" a-join="animate__fadeIn animate__fadeOut">
					<ul className="row g-3">
						{technologies.map((technology, index) => {
							return (
								<li
									key={index.toString()}
									className={[
										'col-unit-2 col-unit-sm-3 col-unit-md-4 col-unit-lg-6',
										index > 5 && index <= 8 ? 'd-none' : 'd-md-flex',
										index > 8 ? 'd-none' : 'd-sm-flex',
										'd-flex flex-center-center'
									].join(' ')}
								>
									<img
										className={`technology_image mw-13-rem mw-md-15-rem`}
										src={technology.src}
										alt={technology.alt}
									/>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</section>
	);
};
