import React from 'react';

import { useAnimation } from '../../../../hooks';

import './index.scss';

export const HeaderLayerTransparent = ({ openMenuState, handleOpenMenu }) => {
	const [animationClassName] = useAnimation({
		isVisible: openMenuState,
		animationStart: 'animate__fadeIn',
		animationEnd: 'animate__fadeOut'
	});

	return (
		<div
			className={[
				'd-lg-none start-0 h-100 w-100 position-absolute top-0 left-0 bg-dark-t is-selectable',
				animationClassName
			].join(' ')}
			onClick={handleOpenMenu}
		/>
	);
};
