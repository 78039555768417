import React, { useContext } from 'react';

import { Title } from '../../components';
import { LanguageContext } from '../../contexts';

import './index.scss';

export const MethodologySection = () => {
	const { language } = useContext(LanguageContext);

	const methodology = [
		{ es: 'Escuchamos tus principales necesidades.', en: 'We listen to your main needs.' },
		{ es: 'Identificamos elementos claves de tu industria.', en: 'We identify key elements of your industry.' },
		{
			es: 'Proponemos una solución 100% adaptada a tu caso.',
			en: 'We propose a solution 100% adapted to your case.'
		},
		{ es: 'Generamos un plan de desarrollo juntos.', en: 'We generate a development plan together.' },
		{
			es: 'Entregamos por etapas para agilizar tiempos y reducir costos.',
			en: 'We deliver in stages to speed up time and reduce costs.'
		},
		{
			es: 'Garantizamos tu satisfacción durante el primer mes o te devolvemos tu dinero.',
			en: 'We guarantee your satisfaction during the first month or we give you your money back.'
		}
	];

	return (
		<section id="methodology" className="bg-light">
			<div className="container py-5">
				<div className="row g-3">
					<Title
						a-join="animate__fadeInUp animate__fadeOutDown"
						className="col-12"
						title={{ es: 'Nuestro', en: 'Our' }[language]}
						accentTitle={{ es: 'Método', en: 'Methodology' }[language]}
					/>
					<div className="col-12">
						<div className="row g-3">
							<ul
								a-join="animate__fadeInLeft animate__fadeOutRight"
								className={[
									'list',
									'col-12 col-md-6',
									'd-flex flex-column flex-center-start order-1'
								].join(' ')}
							>
								{methodology.map((element, index) => (
									<li key={index.toString()} className="d-flex flex-row">
										<h5 className="text-dark h4">
											<strong className="">{index + 1}</strong>
										</h5>
										<h5 className="text-accent me-1 h4">
											<strong className=" ">.</strong>
										</h5>
										<span className="lead">{element[language]}</span>
									</li>
								))}
							</ul>
							<div
								a-join="animate__fadeInRight animate__fadeOutRight"
								className="col-12 col-md-6 d-flex flex-center-center order-0 order-md-2"
							>
								<img className="" src="/assets/illustrations/methodology.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
