import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts';

import { FormButton } from '../../components/FormButton';
import { SVGNewProject, SVGExistingProject } from './Svgs';

import './index.css';

export const NewProjectSection = ({ stateForm, setStateForm }) => {
	const { language } = useContext(LanguageContext);

	const projectTypes = [
		{
			id: 'new_project',
			title: {
				en: 'New Project',
				es: 'Nuevo proyecto'
			},
			Svg: SVGNewProject
		},
		{
			id: 'existing_project',
			title: {
				en: 'Existing project',
				es: 'Projecto existente'
			},
			Svg: SVGExistingProject
		}
	];

	const handleClick = (type) => {
		setStateForm((state) => {
			return {
				...state,
				form: {
					...state.form,
					status: type.id
				}
			};
		});
	};

	return (
		<React.StrictMode>
			{projectTypes.map((type) => {
				return (
					<div key={type.id} className="col-unit-1 col-unit-sm-2 col-unit-md-3 col-unit-lg-5">
						<FormButton
							isActive={stateForm.form.status === type.id}
							Svg={type.Svg}
							icon={type.icon}
							iconActive={type.iconActive}
							text={type.title[language]}
							onClick={() => handleClick(type)}
						/>
					</div>
				);
			})}
		</React.StrictMode>
	);
};
