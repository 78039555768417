export { HeaderSection } from './1.Header';
export { IntroductionSection } from './2.Introduction';
export { UsSection } from './3.Us';
export { ServicesSection } from './4.Services';
export { TechnologiesSection } from './5.Technologies';
export { MethodologySection } from './6.Methodology';
export { KeysSection } from './8.Keys';
export { ClientsSection } from './8.1 Clients';
export { ContactSection } from './9.Contact';
export { FooterSection } from './10.Footer';
