import React from 'react';

export function SVGFormServerDB(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M0-25.379c10.281-27.543 27.806-33.3 53.666-47.272-.703-31.725 10.693-61.989 32.249-85.43 22.113-24.052 52.277-38.059 84.905-39.407 65.28-2.744 119.919 45.608 126.766 108.924 38.194 5.877 69.473 35.094 78.103 72.673 5.231-1.16 10.728-1.896 16.415-2.197 1.427-.056 2.788-.089 4.125-.089 50.94 0 92.568 39.898 94.775 90.838 2.197 52.276-38.516 96.602-90.748 98.81-30.767 1.305-63.975 1.907-104.521 1.907-25.77 0-51.574-.234-77.355-.479-25.681-.235-51.352-.469-76.944-.469-40.088 0-72.85.591-103.103 1.884-1.35.057-12.581.539-13.919.539"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.3}
				transform="matrix(1.55336 0 0 1.59904 212.27 499.79)"
			/>
			<path
				d="M-13.703 68.369s1.559-32.713 5.663-32.713c14.541 0 16.663-19.345 25.124-28.102C21.957 2.511 35.651-.702 39.755-.702"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={18.58}
				transform="matrix(1.82156 0 0 1.61627 736.27 473.08)"
			/>
			<path
				d="M222.071 779.774c0 6.684-5.417 12.102-12.102 12.102-6.684 0-12.102-5.418-12.102-12.102 0-6.683 5.418-12.103 12.102-12.103 6.685 0 12.102 5.42 12.102 12.103"
				fill="#fff"
				fillRule="nonzero"
				stroke="#ffbf10"
				strokeWidth={31.995370799999996}
				strokeLinejoin="round"
				strokeMiterlimit={2}
			/>
			<path
				d="M0 48.648h-152.761a8.609 8.609 0 01-8.609-8.608V8.608A8.609 8.609 0 01-152.761 0H0a8.608 8.608 0 018.608 8.608V40.04A8.608 8.608 0 010 48.648z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={16.51}
				transform="matrix(1.34604 0 0 1.55324 243.347 741.993)"
			/>
			<path
				d="M222.071 686.454c0 6.684-5.417 12.102-12.102 12.102-6.684 0-12.102-5.418-12.102-12.102 0-6.683 5.418-12.103 12.102-12.103 6.685 0 12.102 5.42 12.102 12.103"
				fill="#fff"
				fillRule="nonzero"
				stroke="#ffbf10"
				strokeWidth={31.995370799999996}
				strokeLinejoin="round"
				strokeMiterlimit={2}
			/>
			<path
				d="M0 48.648h-152.761a8.609 8.609 0 01-8.609-8.608V8.608A8.609 8.609 0 01-152.761 0H0a8.608 8.608 0 018.608 8.608V40.04A8.608 8.608 0 010 48.648z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={16.51}
				transform="matrix(1.34604 0 0 1.55324 243.347 648.673)"
			/>
			<g>
				<path
					d="M222.071 593.134c0 6.684-5.417 12.102-12.102 12.102-6.684 0-12.102-5.418-12.102-12.102 0-6.683 5.418-12.103 12.102-12.103 6.685 0 12.102 5.42 12.102 12.103"
					fill="#fff"
					fillRule="nonzero"
					stroke="#ffbf10"
					strokeWidth={31.995370799999996}
					strokeLinejoin="round"
					strokeMiterlimit={2}
				/>
				<path
					d="M0 48.648h-152.761a8.609 8.609 0 01-8.609-8.608V8.608A8.609 8.609 0 01-152.761 0H0a8.608 8.608 0 018.608 8.608V40.04A8.608 8.608 0 010 48.648z"
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.51}
					transform="matrix(1.34604 0 0 1.55324 243.347 555.353)"
				/>
			</g>
			<g>
				<path
					d="M222.071 499.814c0 6.684-5.417 12.102-12.102 12.102-6.684 0-12.102-5.418-12.102-12.102 0-6.683 5.418-12.103 12.102-12.103 6.685 0 12.102 5.42 12.102 12.103"
					fill="#fff"
					fillRule="nonzero"
					stroke="#ffbf10"
					strokeWidth={31.995370799999996}
					strokeLinejoin="round"
					strokeMiterlimit={2}
				/>
				<path
					d="M0 48.648h-152.761a8.609 8.609 0 01-8.609-8.608V8.608A8.609 8.609 0 01-152.761 0H0a8.608 8.608 0 018.608 8.608V40.04A8.608 8.608 0 010 48.648z"
					fill="none"
					stroke="#ffbf10"
					strokeWidth={16.51}
					transform="matrix(1.34604 0 0 1.55324 243.347 462.033)"
				/>
			</g>
		</svg>
	);
}
