import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts';

import './index.scss';

export const FinalMessageSection = () => {
	const { language } = useContext(LanguageContext);

	return (
		<section className="form__final__message__content animate__animated animate__fadeIn">
			<h2 className="form__final__title">
				{
					{
						en: 'Thanks for your preference!',
						es: '¡Muchas gracias por tu preferencia!'
					}[language]
				}
			</h2>
			<p className="form__final__message">
				{
					{
						en: 'We will be soon with contact with you',
						es: 'Nos pondremos en contacto contigo muy pronto'
					}[language]
				}
			</p>
		</section>
	);
};
