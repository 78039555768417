import React, { useContext } from 'react';

import { LanguageContext } from '../../contexts';

import './index.scss';

export const FooterSection = () => {
	const { language } = useContext(LanguageContext);

	const menus = [
		{
			href: '#introduction',
			text: {
				en: 'Home',
				es: 'Inicio'
			}
		},
		{
			href: '#services',
			text: {
				en: 'Our Offer',
				es: 'Nuestra oferta'
			}
		},
		{
			href: '#technologies',
			text: {
				en: 'Technologies',
				es: 'Tecnologías'
			}
		},
		{
			href: '#methodology',
			text: {
				en: 'Our Methodology',
				es: 'Nuetro método'
			}
		},
		{
			href: '#keys',
			text: {
				en: 'Why Us',
				es: '¿Por qué nosotros?'
			}
		},
		{
			href: '#contact',
			text: {
				en: 'Contact',
				es: 'Contacto'
			}
		}
	];

	const contacts = [
		// {
		// 	href: 'tel:+52999-355-7343',
		// 	text: {
		// 		es: 'Teléfono: ',
		// 		en: 'Phone: '
		// 	},
		// 	value: '993.55.73.43'
		// },
		{
			href: 'https://api.whatsapp.com/send?phone=+529993557343',
			text: {
				es: 'WhatsApp: ',
				en: 'WhatsApp: '
			},
			value: '993.55.73.43'
		},
		{
			href: 'mailto:contact@joinlean.com',
			text: {
				es: 'Correo: ',
				en: 'Email: '
			},
			value: 'contact@joinlean.com'
		}
	];

	const getList = ({ title, list, getText }) => {
		return (
			<div className="col-12 col-lg-3" a-join="animate__fadeIn animate__fadeOut" a-join-offset="0.9">
				<div className="row g-2">
					<p className="w-100 text-center text-lg-start fw-bold text-dark mb-0">{title[language]}</p>
					<ul className="">
						{list.map((element, index) => {
							return (
								<li className="w-100 text-center text-lg-start" key={index.toString()}>
									<a className="link-dark" href={element.href}>
										{getText(element)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};

	return (
		<footer id="footer" className="bg-third">
			<div className="container py-content">
				<div className="row g-4">
					<div className="col-12 col-lg-6 d-flex flex-center-center flex-lg-start-center">
						<img className="footer_image" src="/assets/branding/logo-light.svg" alt="JOINLEAN Logo" />
					</div>
					{getList({
						title: {
							es: 'Menú',
							en: 'Menu'
						},
						list: menus,
						getText: (element) => {
							return <React.StrictMode>{element.text[language]}</React.StrictMode>;
						}
					})}
					{getList({
						title: {
							es: 'Contacto',
							en: 'Contact'
						},
						list: contacts,
						getText: (element) => {
							return (
								<React.StrictMode>
									<strong>{element.text[language]}</strong> {element.value}
								</React.StrictMode>
							);
						}
					})}
				</div>
			</div>
		</footer>
	);
};
