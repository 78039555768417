import React from 'react';

export function SVGTwoMonths(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M577.73 437.505c30.515 0 55.254-24.738 55.254-55.253S608.245 327 577.73 327c-30.514 0-55.252 24.737-55.252 55.252 0 30.515 24.738 55.253 55.252 55.253zM652.79 452.686H502.693c-22.498 0-40.739 18.24-40.739 40.742V675.23c0 11.302 9.162 20.465 20.463 20.465 11.3 0 20.464-9.163 20.464-20.465V520.633c.028-3.048 2.446-5.505 5.47-5.633h.477c3.041.128 5.474 2.616 5.474 5.688 0 .006.004.01.004.015v391.61c0 14.612 11.845 26.457 26.456 26.457 14.61 0 26.456-11.845 26.456-26.456h.433V700.399h20.182v211.915h.215c0 14.61 11.847 26.456 26.458 26.456 14.61 0 26.456-11.845 26.456-26.456h.217V520.688c0-3.072 2.431-5.56 5.474-5.688h.477c3.041.128 5.474 2.616 5.474 5.688v154.524c-.002 11.32 9.16 20.483 20.463 20.483s20.465-9.163 20.464-20.483V493.428c0-22.501-18.24-40.742-40.741-40.742z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9949826}
			/>
			<path
				d="M439.029 660.205H89.05V277.67h488.689v25.616"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9949826}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M162.3 375.34h335.993M448.357 468.94H405.13M363.063 468.94H162.3M162.3 562.54h100.381M444.928 562.54H304.04"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9949826}
				strokeLinecap="round"
			/>
			<path
				d="M841.6 197.681c-1.513-23.413-19.984-44.08-41.675-51.677-11.371-3.982-24.999-4.634-35.652 1.76-10.45 6.273-16.078 20.33-9.514 31.337-.76.443-1.518.886-2.275 1.33-10.154-18.863-36.32-20.121-49.93-4.508-15.278 17.523-8.978 43.446 9.026 56.308.566.404.948 1.225.492 1.88-14.205 20.367-10.89 50.864 8.138 67.17 19.601 16.8 51.542 14.207 69.026-4.32.673-.715 2.092-.408 2.29.606 3.085 15.691 12.962 29.777 27.578 36.698 13.12 6.212 29.199 6.636 42.47.625 26.046-11.795 33.68-48.907 13.542-69.62-1.048-1.076.02-2.718 1.412-2.425 13.308 2.797 26.068-6.369 31.592-18.088 5.29-11.223 4.23-24.96-1.418-35.828-12.19-23.46-46.046-29.052-65.102-11.248"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9949826}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx={18.405}
				r={18.405}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.37}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 1.16898 1.16898 0 728.74 328.94)"
			/>
			<circle
				cx={13.015}
				cy={-0.001}
				r={13.015}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={27.37}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="matrix(0 1.16898 1.16898 0 682.06 381.92)"
			/>
		</svg>
	);
}
