import React, { useEffect, useState, useContext } from 'react';

import { HeaderAppbar, HeaderLayerTransparent, HeaderNavbar } from './components';
import { ScreenContext } from '../../contexts';
import { screenTypes, useDelay } from '../../hooks';

import './index.scss';

export const HeaderSection = () => {
	const [ openMenuState, setOpenMenuState ] = useState(null);

	const screenContext = useContext(ScreenContext);
	const { type, isScrollDown } = screenContext;

	useEffect(
		() => {
			if (type === screenTypes.sm) {
				setOpenMenuState(null);
			}
		},
		[ type ]
	);

	const handleOpenMenu = () => {
		setOpenMenuState((state) => {
			return !state;
		});

		return false;
	};

	const [ heightDelayClasses ] = useDelay({
		isVisible: openMenuState,
		startClass: 'h-100',
		endClass: ''
	});

	return (
		<header
			id="header"
			className={[ 'w-100 d-flex', heightDelayClasses, isScrollDown ? 'is-scrolling-down' : '' ].join(' ')}
		>
			<HeaderAppbar handleOpenMenu={handleOpenMenu} isScrollDown={isScrollDown} />
			<HeaderLayerTransparent openMenuState={openMenuState} handleOpenMenu={handleOpenMenu} />
			<HeaderNavbar openMenuState={openMenuState} handleOpenMenu={handleOpenMenu} isScrollDown={isScrollDown} />
		</header>
	);
};
