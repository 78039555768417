import React from 'react';

import './index.scss';

export const Title = ({ className, title, accentTitle, text, isLight, ...rest }) => {
	return (
		<div
			{...rest}
			className={[
				'title',
				'py-content',
				'd-flex flex-column gu',
				isLight ? 'text-light' : 'text-dark',
				className
			].join(' ')}
		>
			<h2 className="title_title fw-bold text-center">
				{' '}
				{`${title} `}
				<strong className="title-accent ms-1 text-accent">{accentTitle}</strong>
			</h2>
			{text && <p className="title_text fw-light text-center lead"> {text}</p>}
		</div>
	);
};
