import React, { useContext } from 'react';

import { Assets } from '../../../../utils';
import { LanguageContext } from '../../../../contexts';

import './index.scss';

export const HeaderNavbar = ({ openMenuState, isScrollDown, handleOpenMenu }) => {
	const { language, handleSetLanguage } = useContext(LanguageContext);

	const menus = [
		{ href: '#introduction', title: { es: 'Inicio', en: 'Home' } },
		{ href: '#services', title: { es: 'Nuestra oferta', en: 'Our Offer' } },
		{ href: '#keys', title: { es: '¿Por qué nosotros?', en: 'Why Us' } },
		{ href: '#contact', title: { es: 'Contacto', en: 'Contact' } }
	];

	return (
		<nav
			className={[
				'header_nav w-100 animate__animated transition',
				openMenuState === null ? 'd-none' : openMenuState ? 'animate__fadeInLeft' : 'animate__fadeOutLeft',
				isScrollDown ? 'bg-primary' : 'bg-transparent'
			].join(' ')}
		>
			<ul className="w-100 h-100 container d-flex flex-column flex-lg-row p-3">
				<li className="d-flex mb-3 mb-lg-0 me-md-auto">
					<img className="navbar_brand h-5-rem w-100" src={Assets.brandingLogoDark} alt="" />
				</li>
				{menus.map((menu) => {
					return (
						<li key={menu.href} className="w-100 w-lg-auto me-lg-2 mb-2 mb-lg-0">
							<a
								className="w-100 w-lg-auto btn btn-outline-hover-accent text-nowrap text-light text-start"
								href={menu.href}
								onClick={handleOpenMenu}
							>
								{menu.title[language]}
							</a>
						</li>
					);
				})}
				<li className="d-flex mt-3 mt-lg-0">
					<button className="w-100 btn btn-outline-accent" onClick={handleSetLanguage}>
						<span className="text-light">
							{
								{
									es: 'Español',
									en: 'English'
								}[language]
							}
						</span>
					</button>
				</li>
			</ul>
		</nav>
	);
};
