import { useState } from 'react';

export const useListSelected = (initState) => {
	const [ list, setList ] = useState(
		initState.map((element) => {
			element.isSelected = null;
			return element;
		})
	);
	const [ serviceSelected, setServiceSelected ] = useState(
	null
	);

	const handleClickElement = (id) => {
		setServiceSelected(id);
		setList((state) => {
			return mapStateOnClickElement({
				state,
				id,
				keepStateElement: false
			});
		});
	};

	const handleClickElementAndKeepState = (id) => {
		setList((state) => {
			return mapStateOnClickElement({
				state,
				id,
				keepStateElement: true
			});
		});
	};

	return {
		list,
		handleClickElement,
		handleClickElementAndKeepState,
		setList,
		serviceSelected
	};
};

const mapStateOnClickElement = ({ state, id, keepStateElement }) => {
	return state.map((element) => {
		if (element.id === id) {
			if (element.isSelected === null) {
				element.isSelected = true;
			} else if (element.isSelected === false) {
				element.isSelected = true;
			} else {
				element.isSelected = false;
			}
		} else {
			if (!keepStateElement) {
				if (element.isSelected === null) {
					element.isSelected = null;
				} else {
					element.isSelected = false;
				}
			}
		}
		return element;
	});
};
