import React, { createContext } from 'react';

import { useScreen, useScreenScroll, useScrollAnimation } from '../hooks';

export const ScreenContext = createContext(null);

export const ScreenManager = ({ children }) => {
	const { ScreenState } = useScreen();

	const { scrollDownState } = useScreenScroll({
		idElement: 'root'
	});

	useScrollAnimation({
		mainElementId: 'root'
	});

	return (
		<ScreenContext.Provider
			value={{
				...ScreenState,
				isScrollDown: scrollDownState
			}}
		>
			{children}
		</ScreenContext.Provider>
	);
};
