import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts';

import { FormButton } from '../../components/FormButton';
import { SVGOneMonth, SVGThreeMonths, SVGTwoMonths, SVGTwoWeeks } from './Svgs';

export const TimeDevelopmentSection = ({ stateForm, setStateForm }) => {
	const { language } = useContext(LanguageContext);

	const projectTypes = [
		{
			id: 'two_weeks',
			title: {
				en: 'Two weeks',
				es: 'Dos semanas'
			},
			Svg: SVGTwoWeeks
		},
		{
			id: 'one_month',
			title: {
				en: 'One month',
				es: 'Un mes'
			},
			Svg: SVGOneMonth
		},
		{
			id: 'two_months',
			title: {
				en: 'Two months',
				es: 'Dos meses'
			},
			Svg: SVGTwoMonths
		},
		{
			id: 'three_months_more',
			title: {
				en: 'More than three months',
				es: 'Más de tres meses'
			},
			Svg: SVGThreeMonths
		}
	];

	const handleClick = (type) => {
		setStateForm((state) => {
			return {
				...state,
				form: {
					...state.form,
					time: type.id
				}
			};
		});
	};

	return (
		<React.StrictMode>
			{projectTypes.map((type) => {
				return (
					<div key={type.id} className="col-unit-1 col-unit-sm-2 col-unit-md-3 col-unit-lg-5">
						<FormButton
							isActive={stateForm.form.time === type.id}
							Svg={type.Svg}
							icon={type.icon}
							iconActive={type.iconActive}
							text={type.title[language]}
							onClick={() => handleClick(type)}
						/>
					</div>
				);
			})}
		</React.StrictMode>
	);
};
