import React, { useContext } from 'react';
import { LanguageContext } from '../../../../contexts';

import './index.scss';

export const ContactInformationSection = ({ stateForm, setStateForm }) => {
	const { language } = useContext(LanguageContext);

	const handleChange = ({ target }) => {
		const id = target.id;
		const value = target.value;

		setStateForm((state) => {
			return {
				...state,
				form: {
					...state.form,
					contact: {
						...state.form.contact,
						[id]: value
					}
				}
			};
		});
	};

	return (
		<section className="form__contact__information">
			<form className="form__contact__information__form p-3">
				<div className="row">
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Nombre:' : 'Name:'}
						id="name"
						type="text"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Apellidos:' : 'Last name:'}
						id="last_name"
						type="text"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Correo:' : 'Email:'}
						id="email"
						type="email"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Teléfono:' : 'Phone:'}
						id="phone"
						type="phone"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Empresa:' : 'Company:'}
						id="company"
						type="text"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12 col-md-6 col-lg-4"
						placeholder={language === 'es' ? 'Puesto de trabajo:' : 'Job Title:'}
						id="job_title"
						type="text"
						onChange={handleChange}
					/>
					<FormControl
						className="col-12"
						placeholder={language === 'es' ? 'Mensaje:' : 'Message:'}
						id="message"
						type="text_area"
						onChange={handleChange}
					/>
				</div>
			</form>
		</section>
	);
};

const FormControl = ({ className, placeholder, id, type, onChange }) => {
	return (
		<div className={[ 'mb-3', className ].join(' ')}>
			<label htmlFor={id}>{placeholder}</label>
			{type === 'text_area' ? (
				<textarea id={id} onChange={onChange} />
			) : (
				<input id={id} type={type} onChange={onChange} />
			)}
		</div>
	);
};
