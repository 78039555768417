import React, { useState, useEffect, createContext } from 'react';

export const LanguageContext = createContext(null);

export const LanguageManager = ({ children }) => {
	const [ language, setLanguage ] = useState('es');

	useEffect(() => {
		const validation = document.URL.includes('/es');
		const _language = validation ? 'es' : 'en';
		setLanguage(_language);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSetLanguage = () => {
		if (language === 'es') {
			setLanguage('en');
			changeHtmlData('en');
		} else {
			setLanguage('es');
			changeHtmlData('es');
		}
	};

	return (
		<LanguageContext.Provider
			value={{
				language,
				handleSetLanguage
			}}
		>
			{children}
		</LanguageContext.Provider>
	);
};

const changeHtmlData = (language) => {
	if (language === 'es') {
		document.documentElement.setAttribute('lang', 'es');
		document
			.querySelector('meta[name="description"]')
			.setAttribute(
				'content',
				'Desarrollemos juntos los productos tecnológicos que conecten mejor con tus usuarios finales en tiempo récord. Contáctanos para ayudarte contacto@joinlean.com.'
			);
	} else {
		document.documentElement.setAttribute('lang', 'en');
		document
			.querySelector('meta[name="description"]')
			.setAttribute(
				'content',
				"Let's develop together the technology products that best connect with your end users at the right time. Contact us to help you contact@joinlean.com."
			);
	}
};
