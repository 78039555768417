import React from 'react';

import { Assets } from '../../../../utils';

import { SvgIcon } from '../MenuIcon';

import './index.scss';

export const HeaderAppbar = ({ handleOpenMenu, isScrollDown }) => {
	return (
		<div
			className={`appbar w-100 d-flex d-lg-none container-fluid flex-between py-3 transition ${isScrollDown
				? 'bg-primary'
				: ''}`}
		>
			<img className="icon" src={Assets.brandingIconDark} alt="" />
			<SvgIcon onClick={handleOpenMenu} />
		</div>
	);
};
