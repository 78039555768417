import { useState, useEffect } from 'react';

export const screenTypes = {
	sm: 'sm', // MOBILE
	md: 'md', // TABLE
	lg: 'lg', // DESKTOP
	xl: 'xl', // DESKTOP EXTENDED
	xxl: 'xxl' // DESKTOP EXTENDED EXTRA
};

export const useScreen = () => {
	const [ ScreenState, setScreenState ] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
		type: screenTypes.mobile
	});

	const defineType = (width) => {
		if (width < 768) {
			return screenTypes.sm;
		} else if (width < 992) {
			return screenTypes.md;
		} else if (width < 1200) {
			return screenTypes.lg;
		} else if (width < 1800) {
			return screenTypes.xl;
		} else {
			return screenTypes.xxl;
		}
	};

	useEffect(() => {
		const screenResize = () => {
			const width = window.innerWidth;
			const height = window.innerHeight;

			setScreenState({
				width: width,
				height: height,
				type: defineType(width)
			});
		};

		window.addEventListener('resize', screenResize);
		screenResize();

		return () => {
			window.removeEventListener('resize', screenResize);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { ScreenState };
};
