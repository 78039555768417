import React from 'react';

export function SVGNewProject(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M817.617 252.712v664.593H183.222V84.6h449.085"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
			/>
			<path
				d="M632.31 252.711h185.31L632.31 84.6v168.111z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.999702}
				strokeLinecap="butt"
			/>
		</svg>
	);
}
