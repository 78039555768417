import React from 'react';

export function SVGThreeMonths(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M501.917 628.014h84.172V338.332H382.765v14.972M431.317 371.887h154.772"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.004462}
			/>
			<path d="M417.106 461.131h89.184V401.01h-66.433" fill="none" stroke="#ffbf10" strokeWidth={20.004462} />
			<path
				d="M0 0h76"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.33}
				transform="matrix(.86094 0 0 1.09314 486.694 488.853)"
			/>
			<path
				d="M0 0h57"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.33}
				transform="matrix(.86094 0 0 1.09314 503.052 515.089)"
			/>
			<path
				d="M0 0h65"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.33}
				transform="matrix(.86094 0 0 1.09314 496.165 543.51)"
			/>
			<path
				d="M0 0h57"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.33}
				transform="matrix(.86094 0 0 1.09314 503.052 570.839)"
			/>
			<path
				d="M0 0h60"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={20.33}
				transform="matrix(.86094 0 0 1.09314 500.469 598.166)"
			/>
			<ellipse
				cy={-4.179}
				rx={4.5}
				ry={4.179}
				fill="#ffbf10"
				transform="matrix(-1.09314 0 0 1.09314 529.792 359.16)"
			/>
			<ellipse
				cy={-4.179}
				rx={4.5}
				ry={4.179}
				fill="#ffbf10"
				transform="matrix(-1.09314 0 0 1.09314 547.283 359.16)"
			/>
			<ellipse
				cy={-4.179}
				rx={4.5}
				ry={4.179}
				fill="#ffbf10"
				transform="matrix(-1.09314 0 0 1.09314 564.773 359.16)"
			/>
			<g>
				<path
					d="M506.29 663.33h110.902M276.208 663.33H29.206V305.114h587.986v358.218"
					fill="none"
					stroke="#ffbf10"
					strokeWidth={29.9957616}
				/>
				<path
					d="M388.718 470.032c28.536 0 51.67-23.133 51.67-51.668 0-28.536-23.134-51.668-51.67-51.668-28.534 0-51.667 23.132-51.667 51.668 0 28.535 23.133 51.668 51.667 51.668zM458.904 484.222H318.545c-21.038 0-38.096 17.057-38.096 38.1v170.007c0 10.568 8.568 19.136 19.136 19.136 10.567 0 19.136-8.568 19.136-19.136V547.76c.026-2.85 2.287-5.148 5.115-5.268h.446c2.844.12 5.12 2.446 5.12 5.32l.002.013V914.03c0 13.664 11.077 24.74 24.74 24.74 13.662 0 24.74-11.076 24.74-24.74h.405V715.864h18.873V914.03h.201c0 13.664 11.078 24.74 24.741 24.74 13.662 0 24.74-11.076 24.74-24.74h.202V547.812c0-2.873 2.274-5.199 5.12-5.32h.445c2.845.121 5.12 2.447 5.12 5.32v144.499c-.003 10.586 8.565 19.154 19.135 19.154 10.57 0 19.138-8.568 19.136-19.154v-169.99c0-21.042-17.057-38.099-38.098-38.099zM742.896 470.032c28.535 0 51.669-23.133 51.669-51.668 0-28.536-23.134-51.668-51.67-51.668-28.535 0-51.667 23.132-51.667 51.668 0 28.535 23.132 51.668 51.668 51.668zM813.082 484.222h-140.36c-21.038 0-38.095 17.057-38.095 38.1v170.007c0 10.568 8.568 19.136 19.135 19.136 10.567 0 19.137-8.568 19.137-19.136V547.76c.026-2.85 2.286-5.148 5.113-5.268h.447c2.845.12 5.12 2.446 5.12 5.32 0 .005.003.008.003.013V914.03c0 13.664 11.077 24.74 24.74 24.74 13.662 0 24.74-11.076 24.74-24.74h.404V715.864h18.873V914.03h.201c0 13.664 11.078 24.74 24.741 24.74 13.662 0 24.74-11.076 24.74-24.74h.201V547.812c0-2.873 2.274-5.199 5.12-5.32h.447c2.844.121 5.119 2.447 5.119 5.32v144.499c-.002 10.586 8.566 19.154 19.135 19.154 10.57 0 19.138-8.568 19.137-19.154v-169.99c0-21.042-17.058-38.099-38.098-38.099z"
					fill="none"
					stroke="#ffbf10"
					strokeWidth={29.9957616}
					strokeLinecap="butt"
					strokeLinejoin="miter"
				/>
			</g>
			<g>
				<path
					d="M126.78 366.71h176.186M68.653 365.127l8.435 9.236 17.99-19.677M68.653 426.889l8.435 9.236 17.99-19.677M68.653 485.919l8.435 9.235 17.99-19.676M202.016 484.222H126.78M202.016 426.286H126.78M202.016 546.47H126.78"
					fill="none"
					stroke="#ffbf10"
					strokeWidth={20.004462}
					strokeLinejoin="miter"
				/>
			</g>
			<g>
				<path
					d="M816.94 327.345c0 4.904 7.444 10.622 14.125 12.686 1.772.55 2.978 2.165 2.978 3.99v19.986l34.326-22.654a4.297 4.297 0 012.37-.712h78.633c7.457 0 13.522-5.964 13.522-13.296v-74.938c0-7.335-6.065-13.3-13.522-13.3H830.46c-7.455 0-13.519 5.965-13.519 13.3v74.938zm13.52-96.6h118.912c12.145 0 22.024 9.718 22.024 21.662v74.938c0 11.944-9.879 21.66-22.024 21.66h-77.34l-39.87 26.316a4.32 4.32 0 01-4.37.217 4.168 4.168 0 01-2.252-3.69v-24.921c-7.749-3.228-17.104-10.218-17.104-19.582v-74.938c0-11.944 9.88-21.661 22.023-21.661"
					fill="#ffbf10"
					fillRule="nonzero"
					stroke="#ffbf10"
					strokeWidth={10.996988400000001}
					strokeLinecap="butt"
					strokeLinejoin="miter"
				/>
				<path
					d="M830.906 291.321c.077-2.047 3.017-6.205 5.863-6.205 19.102 0 93.6-.038 108.11.121 2.476.027 4.283 4.092 4.262 6.084-.021 1.993-1.945 5.867-4.388 5.871-16.16.026-89.52.142-108.446.211-2.712.01-5.478-4.034-5.4-6.082zM830.906 318.595c.077-2.047 3.017-6.205 5.863-6.205 19.102 0 93.6-.038 108.11.121 2.476.027 4.283 4.092 4.262 6.084-.021 1.993-1.945 5.867-4.388 5.871-16.16.026-89.52.142-108.446.211-2.712.01-5.478-4.034-5.4-6.082zM830.906 264.046c.077-2.047 3.017-6.205 5.863-6.205 19.102 0 93.6-.038 108.11.121 2.476.027 4.283 4.092 4.262 6.084-.021 1.993-1.945 5.867-4.388 5.871-16.16.026-89.52.142-108.446.211-2.712.01-5.478-4.034-5.4-6.082z"
					fill="none"
					stroke="#ffbf0f"
					strokeWidth={5}
					strokeMiterlimit={1.5}
				/>
			</g>
		</svg>
	);
}
