import { useLayoutEffect, useState } from 'react';

export const useDelay = ({ isVisible = true, startClass = '', endClass = ``, duration = 800 }) => {
	const [ styles, setStyles ] = useState([]);

	const updateStyles = () => {
		setStyles([ isVisible ? `${startClass}` : `${endClass}` ]);
	};

	const updateByFirstLoad = () => {
		let timer;
		if (!isVisible) {
			timer = setTimeout(() => {
				// updateStyles();
				updateStyles();
			}, isVisible === null ? 0 : duration);
		} else {
			updateStyles();
		}

		return () => {
			clearInterval(timer);
		};
	};

	useLayoutEffect(
		() => {
			updateByFirstLoad();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ isVisible ]
	);

	return [ styles.filter((element) => element !== '').join(' ') ];
};
