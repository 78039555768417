import { useLayoutEffect } from 'react';

// a-join="animate__fadeIn animate__fadeOut"

export const useScrollAnimation = ({ mainElementId, screenPortion = 0.7 }) => {
	useLayoutEffect(() => {
		const defineAnimations = () => {
			let mainPosition = getMainPosition(mainElementId);
			const animatedElements = getAnimatedElements();

			animatedElements.forEach((element) => {
				defineAnimationByPosition(mainPosition, element);
			});
		};

		let element;
		setTimeout(() => {
			let element = document.getElementById(mainElementId);

			if (!element) return;

			element.addEventListener('scroll', defineAnimations);
			defineAnimations();
		}, 50);

		return () => {
			if (!element) return;
			element.removeEventListener('scroll', defineAnimations);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const defineAnimationByPosition = (mainPosition, element) => {
		let classes = element.getAttribute('class');
		const aJoin = element.getAttribute('a-join');
		const aJoinIn = aJoin.split(' ')[0];
		const aJoinOut = aJoin.split(' ')[1];
		let aJoinInit = element.getAttribute('a-join-init');
		let aJoinLoop = element.getAttribute('a-join-loop');
		let aJoinDelay = element.getAttribute('a-join-delay');
		let aJoinSpeedIn = element.getAttribute('a-join-speed-in');
		let aJoinSpeedOut = element.getAttribute('a-join-speed-out');
		const animated = getAnimatedElementState(mainPosition, element, screenPortion);

		// Validate init state value and if does not have set it false
		if (!aJoinInit) {
			element.setAttribute('a-join-init', 'false');
			aJoinInit = 'false';
		}

		if (!aJoinLoop) {
			element.setAttribute('a-join-loop', 'true');
			aJoinLoop = 'true';
		}

		if (!aJoinDelay) {
			element.setAttribute('a-join-delay', '0');
			aJoinDelay = 0;
		}

		if (!aJoinSpeedIn) aJoinSpeedIn = '';
		if (!aJoinSpeedOut) aJoinSpeedOut = '';

		// Stop animation
		if (!classes) return;
		if (animated && classes.includes(aJoinIn)) return;
		if (!animated && classes.includes(aJoinOut)) return;

		// Clean general classes
		classes = cleanClasses(classes);

		if (animated) {
			setTimeout(() => {
				changeClasses({
					classes,
					element,
					removeElements: aJoinOut,
					addElements: `${aJoinIn} ${aJoinSpeedIn}`
				});
			}, aJoinDelay);

			// Set Join Init State
			element.setAttribute('a-join-init', 'true');
		} else {
			// Already pass one time for the animation
			if (aJoinInit === 'true') {
				// Will repeat the animation, so show the reverse option
				if (aJoinLoop === 'true') {
					setTimeout(() => {
						changeClasses({
							classes,
							element,
							removeElements: aJoinIn,
							addElements: `${aJoinOut} ${aJoinSpeedOut}`
						});
					}, aJoinDelay);
				}
			} else {
				// Didn't pass yet for the animation
				changeClasses({
					classes,
					element,
					removeElements: '',
					addElements: 'opacity--none'
				});
			}
		}
	};
};

const cleanClasses = (classes) => {
	classes = classes.replace('animate__animated', '');
	classes = classes.replace('opacity--none', '');
	classes = classes.replace('animate__faster', '');
	classes = classes.replace('animate__fast', '');
	classes = classes.replace('animate__slow', '');
	classes = classes.replace('animate__slower', '');

	return classes;
};

const changeClasses = ({ classes, element, removeElements, addElements }) => {
	if (classes.includes(removeElements)) {
		classes = classes.replace(removeElements, '');
	}
	classes = `${classes}   animate__animated   ${addElements}`.replace('  ', '');
	element.setAttribute('class', classes);
};

const getAnimatedElementState = (mainPosition, element, screenPortion) => {
	const elementPosition = element.offsetParent.offsetTop + element.offsetTop;

	const aJoinOffset = element.getAttribute('a-join-offset');
	const offsetPosition = window.innerHeight * (aJoinOffset || screenPortion);

	let animated = false;
	if (mainPosition > elementPosition - offsetPosition) {
		animated = true;
	}
	return animated;
};

const getMainPosition = (mainElementId) => {
	const mainElement = document.getElementById(mainElementId);
	return mainElement.scrollTop;
};

const getAnimatedElements = () => {
	return document.querySelectorAll('[a-join]');
};
