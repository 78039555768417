import { useRef } from 'react';

export const useClick = ({
    onClick = () => { }
}) => {
    const elementRef = useRef(null);

    const handleClick = (e) => {
        e.preventDefault();

        if (elementRef?.current?.blur) {
            elementRef.current.blur();
        }

        if (onClick) {
            onClick();
        }
    };

    return [
        elementRef,
        handleClick
    ];
}