import React from 'react';

export function SVGFormMobileApp(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M698.08 939.345H302.418c-31.254 0-56.682-25.435-56.682-56.707v-763.45c0-31.271 25.428-56.708 56.682-56.708H698.08c31.238 0 56.664 25.437 56.664 56.709v763.45c0 31.271-25.426 56.706-56.664 56.706z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.998403500000002}
			/>
			<ellipse
				cx={0.01}
				cy={-16.372}
				rx={16.376}
				ry={16.372}
				fill="none"
				stroke="#ffbf10"
				strokeWidth={19.95}
				transform="matrix(-1.60393 0 0 1.60393 500.27 892.4)"
			/>
			<path
				d="M0 411.653h-252.793c-2.48 0-4.491-2.236-4.491-4.997V4.996c0-2.76 2.011-4.996 4.491-4.996H0c2.483 0 4.494 2.236 4.494 4.996v401.66c0 2.761-2.011 4.997-4.494 4.997z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={21.44}
				transform="matrix(1.5107 0 0 1.47372 691.19 186.92)"
			/>
			<path
				d="M524.01 122.948h-94.28a7.207 7.207 0 01-7.21-7.21 7.205 7.205 0 017.21-7.208h94.28a7.206 7.206 0 017.208 7.208 7.207 7.207 0 01-7.208 7.21zm46.748 0h-27.244a7.208 7.208 0 01-7.21-7.21 7.206 7.206 0 017.21-7.208h27.244a7.205 7.205 0 017.21 7.208 7.207 7.207 0 01-7.21 7.21z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.998403500000002}
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				d="M-162.612-33.375h129.19v196.217h-129.19z"
				transform="matrix(.00213 -1.47372 -1.5107 -.00208 598.238 198.938)"
			/>
			<path
				d="M0-3.522s6.58-33.787 34.76-33.647c28.178.139 42.54 23.251 42.54 23.251l-.068 47.705"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				transform="matrix(1.5107 0 0 1.47372 531.69 351.844)"
			/>
			<path
				d="M0-39.92s-29.275-18.708-65.242-1.874l-.085 60.502"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				transform="matrix(1.5107 0 0 1.47372 450.577 410.675)"
			/>
			<path
				d="M0-26.868s-37.589-18.285-97.836-17.857c-60.247.426-98.41 38.486-98.41 38.486l-.034 24.248 196.217.276L0-26.868z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				transform="matrix(1.5107 0 0 1.47372 648.407 411.706)"
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				d="M401.801 488.869H598.12v12.914H401.801z"
				transform="matrix(-1.5107 0 0 1.47372 1255.466 -230.203)"
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				d="M401.801 440.94H598.12v12.913H401.801z"
				transform="matrix(-1.5107 0 0 1.47372 1255.466 -88.933)"
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				d="M401.801 393.012H598.12v12.913H401.801z"
				transform="matrix(-1.5107 0 0 1.47372 1255.466 52.333)"
			/>
			<path
				fill="none"
				stroke="#ffbf10"
				strokeWidth={13.4}
				d="M401.801 345.083H598.12v12.913H401.801z"
				transform="matrix(-1.5107 0 0 1.47372 1255.466 193.6)"
			/>
			<g transform="matrix(-.00212 1.47372 1.5107 .00207 422.41 279.43)">
				<circle cx={11.768} cy={-0.016} r={11.768} fill="#fff" stroke="#ffbf10" strokeWidth={18.76} />
			</g>
		</svg>
	);
}
