// @ts-ignore
export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY;

// @ts-ignore
export const EMAIL_SENDER = process.env.EMAIL_SENDER;

// @ts-ignore
export const EMAIL_RECEIVER = process.env.EMAIL_RECEIVER;

export const REACT_APP_URL =
  // @ts-ignore
  process.env.REACT_APP_URL || 'https://www.joinlean.com/api';
