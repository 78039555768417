import React from 'react';

import {
	TypeProjectSection,
	NewProjectSection,
	TimeDevelopmentSection,
	ContactInformationSection,
	FinalMessageSection
} from '../../sections';

import './index.scss';

export const ContactFormBody = ({ stateForm, setStateForm }) => {
	const getFormBody = () => {
		if (stateForm.sectionSelected === 0) {
			return <TypeProjectSection stateForm={stateForm} setStateForm={setStateForm} />;
		} else if (stateForm.sectionSelected === 1) {
			return <NewProjectSection stateForm={stateForm} setStateForm={setStateForm} />;
		} else if (stateForm.sectionSelected === 2) {
			return <TimeDevelopmentSection stateForm={stateForm} setStateForm={setStateForm} />;
		} else if (stateForm.sectionSelected === 3) {
			return <ContactInformationSection stateForm={stateForm} setStateForm={setStateForm} />;
		} else if (stateForm.sectionSelected === 4) {
			return <FinalMessageSection stateForm={stateForm} setStateForm={setStateForm} />;
		}
	};

	return (
		<div className="form_body col-12">
			<div className=" w-100 row g-3 flex-center">{getFormBody()}</div>
		</div>
	);
};
