import React from 'react';

import './index.scss';

export const KeysCard = ({ className, icon, title, description }) => {
	return (
		<div
			a-join="animate__fadeInUp animate__fadeOutUp"
			a-join-loop="false"
			className={[ 'key_card', 'col-12 col-unit-sm-2 col-unit-lg-3', 'd-flex flex-row flex-sm-column' ].join(' ')}
		>
			<img className="key_card_image h-100 w-100 me-3 mb-sm-3 mx-sm-auto" src={icon} alt="JOINLEAN Key" />
			<div className="w-100 d-flex flex-column mb-sm-auto">
				<h4 className="w-100 text-dark text-start text-sm-center">{title}</h4>
				<p className="w-100 text-justify text-sm-center">{description}</p>
			</div>
		</div>
	);
};
