import { useEffect } from 'react';

export const ClickManager = ({ children }) => {
	useEffect(() => {
		const clickElement = function(e) {
			if (e.target.matches('button')) {
				if (e.target.blur) {
					e.target.blur();
				}
			}
		};
		window.addEventListener('click', clickElement);
		window.addEventListener('touchstart', clickElement);

		return () => {
			window.removeEventListener(clickElement);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
};
