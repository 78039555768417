import React, { useContext } from 'react';

import { Title } from '../../components';
import { KeysCard } from './components';
import { LanguageContext } from '../../contexts';

import './index.scss';

export const ClientsSection = () => {
	const { language } = useContext(LanguageContext);
	const services = [
		{
			image: '/assets/clients/neopetrol.png',
			link: 'https://www.neopetrol.com/',
			minWidth: 180,
		},
		{
			image: '/assets/clients/mcno.png',
			link: 'https://mcno.com.mx/',
		},
		{
			image: '/assets/clients/fundacion-amar.png',
			link: 'https://www.fundacionamar.org/',
		},
	];


	const buildTitle = () => {
		return (
			<Title
				a-join="animate__fadeIn animate__fadeOut"
				a-join-loop="false"
				className="col-12"
				isLight
				title={
					{
						es: 'Nuestros clientes',
						en: 'Our clients'
					}[language]
				}
				accentTitle={
					{
						es: 'primero',
						en: 'first'
					}[language]
				}
				text={
					{
						es:
							'Tu satisfacción es nuestra prioridad',
						en:
							'Your satisfaction is our priority'
					}[language]
				}
			/>
		);
	};

	return (
		<section id="clients" className=" d-flex flex-center-center bg-dark">
			<div className="container">
				<div className="row gy-4 py-content">
					{buildTitle()}
					<div className="col-12">
						<div className="row g-4">
							{services.map((service) => {
								return (
									<KeysCard
										className=""
										key={service.title}
										title={service.title}
										description={service.description}
										icon={service.image}
										href={service.link}
										minWidth={service?.minWidth}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
