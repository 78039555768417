import React from 'react';

export function SVGFormWebsites(props) {
	return (
		<svg
			viewBox="0 0 1001 1001"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path fill="none" stroke="#ffbf10" strokeWidth={31.9941601} d="M961.692 195.598H38.964v694.734h922.728z" />
			<path
				d="M38.97 303.283h292.06l71.297 44.487h559.37"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9941601}
			/>
			<ellipse
				cx={-14.601}
				rx={14.602}
				ry={14.601}
				fill="none"
				stroke="#ffbf0f"
				strokeWidth={8.02}
				strokeLinejoin="round"
				strokeMiterlimit={2}
				transform="matrix(0 -1.86991 -1.86991 0 876.83 242.79)"
			/>
			<ellipse
				cx={-14.601}
				rx={14.602}
				ry={14.601}
				fill="none"
				stroke="#ffbf0f"
				strokeWidth={8.02}
				strokeLinejoin="round"
				strokeMiterlimit={2}
				transform="matrix(0 -1.86991 -1.86991 0 758.98 242.79)"
			/>
			<ellipse
				cx={-14.601}
				rx={14.602}
				ry={14.601}
				fill="none"
				stroke="#ffbf0f"
				strokeWidth={8.02}
				strokeLinejoin="round"
				strokeMiterlimit={2}
				transform="matrix(0 -1.86991 -1.86991 0 641.13 242.79)"
			/>
			<path
				d="M438.03 705.943H125.835c-12.341 0-22.438-10.098-22.438-22.439V463.109c0-12.341 10.097-22.439 22.438-22.439H438.03c12.341 0 22.439 10.098 22.439 22.439v220.395c0 12.341-10.098 22.439-22.439 22.439z"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9941601}
			/>
			<path
				d="M537.09 472.42h202.906M815.96 472.42h81.95M537.09 555.85h367.13M537.088 641.558l245.971-2.005M833.08 639.55h64.837"
				fill="none"
				stroke="#ffbf10"
				strokeWidth={31.9941601}
				strokeLinecap="round"
			/>
		</svg>
	);
}
