import React, { useState, useEffect, useContext } from 'react';

import { LanguageContext, ScreenContext } from '../../contexts';

import './index.scss';

export const IntroductionSection = () => {
	const { language } = useContext(LanguageContext);
	const [ state, setstate ] = useState(0);
	const { type } = useContext(ScreenContext);

	const getCustomType = () => {
		if (type === 'sm') {
			return 'xs';
		} else if (type === 'xl' || type === 'xxl') {
			return 'lg';
		}

		return type;
	};

	const elements = [
		{
			src: `/assets/illustrations/introducction/introduction-one-${getCustomType()}.png`,
			title: {
				es: 'Soluciones digitales para desarrollar tu negocio',
				en: 'Digital Solutions To Develop Your Business'
			}
		},
		{
			src: `/assets/illustrations/introducction/introduction-two-${getCustomType()}.png`,
			title: {
				es: 'Servicios ágiles y modulares a la medida de tu presupuesto',
				en: 'Agile and modular services tailored to your budget'
			}
		},
		{
			src: `/assets/illustrations/introducction/introduction-three-${getCustomType()}.png`,
			title: {
				es: 'Impulso extra para tus necesidades en tecnología',
				en: 'Nearshore outsourcing for your technology needs'
			}
		}
	];

	useEffect(() => {
		const randomImages = () => {
			setstate((state) => {
				if (state === 2) {
					state = 0;
				} else {
					state += 1;
				}

				return state;
			});
		};
		setInterval(randomImages, 6000);
		return () => {
			clearInterval(randomImages);
		};
	}, []);

	return (
		<section id="introduction" className="d-flex flex-column gd-primary text-light">
			<div className="introduction_body d-flex flex-center-center position-relative h-100 container py-content">
				<FlexBody isVisible={state === 0} src={elements[0].src} title={elements[0].title[language]} />
				<FlexBody isVisible={state === 1} src={elements[1].src} title={elements[1].title[language]} />
				<FlexBody isVisible={state === 2} src={elements[2].src} title={elements[2].title[language]} />
			</div>
			<div className="w-100 mb--3">
				<img className="w-100 h-100" src={`/assets/illustrations/effects/introduction-${type}.svg`} alt="" />
			</div>
		</section>
	);
};

export const FlexBody = ({ isVisible, title, src }) => {
	return (
		<div
			className={[
				'w-100 row g-3 flex-center position-absolute',
				isVisible ? 'animate__animated animate__fadeIn' : 'opacity--none'
			].join(' ')}
		>
			<div className="col-12 col-md-6 d-flex flex-center mt-auto mt-md-0">
				<img className="introduction_image" src={src} alt="JOINLEAN Description" />
			</div>
			<div className="col-12 col-md-6 d-flex flex-center-center mb-auto mb-md-0">
				<h1 className="w-100 m-0 text-center">{title}</h1>
			</div>
		</div>
	);
};
