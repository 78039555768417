import React, { useContext } from 'react';

import { LanguageContext } from '../../../../contexts';

import './index.scss';

export const ContactFormHeader = ({ stateForm, setStateForm, isAfterLastOne }) => {
	const { language } = useContext(LanguageContext);

	const handleMove = (index) => {
		if (isDisabled(index)) return;

		setStateForm((state) => {
			return {
				...state,
				sectionSelected: index
			};
		});
	};

	const isDisabled = (index) => {
		const form = stateForm.form;

		if (index === 0 && form.type) {
			return false;
		} else if (index === 1 && form.status) {
			return false;
		} else if (index === 2 && form.time) {
			return false;
		} else if (index === 3) {
			if (!form.contact) return true;
			if (!form.contact.name) return true;
			return false;
		}

		return true;
	};

	return (
		<div className="form__form__header d-flex flex-column flex-center">
			{!isAfterLastOne && (
				<React.StrictMode>
					<h4 className="form__form__title text-center mb-2">
						{stateForm.sections[stateForm.sectionSelected].title[language]}
					</h4>
					<div className="d-flex justify-content-center">
						{stateForm.sections.map((section, index) => {
							const disabled = isDisabled(index);
							return (
								<div
									key={index.toString()}
									onClick={() => handleMove(index)}

									className={[
										'form__form__indicator m-2',
										index === stateForm.sectionSelected
											? 'bg-accent is-selectable'
											: disabled ? 'bg-dark-t is-disabled' : 'bg-primary'
									].join(' ')}
								/>
							);
						})}
					</div>
				</React.StrictMode>
			)}
		</div>
	);
};
