import React, { useContext } from 'react';

import { Title } from '../../components';
import { LanguageContext, ScreenContext } from '../../contexts';
import { useListSelected } from '../../hooks';
import { ServiceCard } from './components';

import './index.scss';

export const ServicesSection = () => {
	const { language } = useContext(LanguageContext);
	const { type } = useContext(ScreenContext);

	const { list: services, serviceSelected,  handleClickElement: handleClickService } = useListSelected([
		{
			id: 'web',
			title: {
				es: 'Aplicaciones Web',
				en: 'Web Applications'
			},
			description: {
				es:
					'Plataformas ligeras y útiles para que tus clientes accedan a tus servicios directamente desde su navegador web.',
				en:
					'Light and useful platforms for your customers to access your services directly from their web browser.'
			},
			image: '/assets/illustrations/services/web-apps.svg',
			isSelected: null
		},
		{
			id: 'mobile',
			title: {
				es: 'Aplicaciones Móviles',
				en: 'Mobile Applications'
			},
			description: {
				es:
					'Nativas o híbridas, tú eliges. Aplicaciones intuitivas con las que tus clientes accederán a tus servicios desde sus dispositivos móviles.',
				en:
					'Native or hybrid, you choose. Intuitive applications with which your customers will access your services from their mobile devices.'
			},
			image: '/assets/illustrations/services/mobile-apps.svg',
			isSelected: null
		},
		{
			id: 'api',
			title: {
				es: 'Servidores API REST',
				en: 'REST API Servers'
			},
			description: {
				es:
					'Integración versatil para las aplicaciones y sistemas que desarrollemos juntos, en una de las arquitecturas más extendidas actualmente.',
				en:
					'Versatile integration for the applications and systems that we will develop together, in one of the most used architectures nowadays.'
			},
			image: '/assets/illustrations/services/rest-api.svg',
			isSelected: null
		},
		{
			id: 'test',
			title: {
				es: 'Pruebas unitarias de software',
				en: 'Software unit testing'
			},
			description: {
				es:
					'Análisis de calidad de los productos de software que necesites, y en el cualquiera de las fases de desarrollo en la que te encuentres.',
				en:
					'Quality analysis of the software products you need, and in any of the development phases you are in.'
			},
			image: '/assets/illustrations/services/test.svg',
			isSelected: null
		},
		{
			id: 'db',
			title: {
				es: 'Manejo de bases de datos',
				en: 'Database Management'
			},
			description: {
				es:
					'Creación, manipulación, almacenamiento y gestión profesional de cada uno de los datos de sus proyectos.',
				en: 'Professional creation, manipulation, storage and management of each of your project data.'
			},
			image: '/assets/illustrations/services/db.svg',
			isSelected: null
		}
	]);

	return (
		<section id="services" className="gd-primary">
			<div className="container g-3 py-content">
				<div className="row">
					<Title
						a-join="animate__fadeInUp animate__fadeOutDown"
						a-join-loop="false"
						className="col-12"
						title={
							{
								es: 'Nuestra',
								en: 'Our'
							}[language]
						}
						accentTitle={
							{
								es: 'oferta',
								en: 'offer'
							}[language]
						}
						text={
							{
								es:
									'Desarrollemos juntos los productos tecnológicos que conecten mejor con tus usuarios finales en el momento justo.',
								en:
									"Let's develop together the technology products that best connect with your end users at the right time."
							}[language]
						}
						isLight="true"
					/>
					<div className="col-12">
						<div className="row g-3 flex-center">
							{services.map((service, index) => {
								return (
									<ServiceCard
										key={index.toString()}
										index={index}
										service={service}
										language={language}
										onClick={handleClickService}
										isSelected={service.id === serviceSelected}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div className="col-12 mb--3">
				<img className="w-100" src={`/assets/illustrations/effects/services-${type}.svg`} alt="" />
			</div>
		</section>
	);
};
