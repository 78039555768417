import { useState, useLayoutEffect } from 'react';

export const useScreenScroll = ({ classElement, idElement }) => {
	const [ scrollDownState, setScrollDownState ] = useState(null);

	const getElement = () => {
		let element;
		if (classElement) {
			element = document.getElementsByClassName(classElement)[0];
		} else {
			element = document.getElementById(idElement);
		}

		return element;
	};

	useLayoutEffect(() => {
		let element;

		const screenScroll = () => {
			let element = getElement();

			if (element.scrollTop > 60) {
				setScrollDownState(true);
			} else {
				setScrollDownState(false);
			}
		};

		setTimeout(() => {
			let element = getElement();
			if (!element) return;

			element.addEventListener('scroll', screenScroll);
			screenScroll();
		}, 300);

		return () => {
			if (!element) return;
			element.removeEventListener('scroll', screenScroll);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { scrollDownState };
};
