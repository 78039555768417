import React, { useContext } from 'react';

import { Title } from '../../components';
import { KeysCard } from './components';
import { LanguageContext } from '../../contexts';

import './index.scss';

export const KeysSection = () => {
	const { language } = useContext(LanguageContext);
	const services = [
		{
			title: language === 'es' ? 'Garantía de satisfacción' : 'Satisfaction Guarantee',
			description:
				language === 'es'
					? 'Paga nuestro servicio sólo si estás satisfecho con él.  Además, te damos un mes de asistencia luego de la entrega final.'
					: 'Pay for our service only if you are satisfied with it.  In addition, we give you one month of assistance after final delivery.',
			image: '/assets/illustrations/keys/satisfaction.svg'
		},
		{
			title: language === 'es' ? 'Soluciones rápidas' : 'Quick solutions',
			description:
				language === 'es'
					? 'Estamos listos para entregarte soluciones en 1 ó 2 semanas, dependiendo del proyecto.'
					: 'We are ready to deliver solutions in 1 or 2 weeks, depending on the project.',
			image: '/assets/illustrations/keys/quick.svg'
		},
		{
			title: language === 'es' ? 'Innovación' : 'Innovation',
			description:
				language === 'es'
					? 'Nuestro equipo se certifica constamente para mantenerse al día con las tecnologías para enfrentar mejor los retos tecnológicos de ahora.'
					: "Our team is constantly certifying to keep up with the latest technologies and to better meet today's technological challenges.",
			image: '/assets/illustrations/keys/innovation.svg'
		},
		{
			title: language === 'es' ? 'Estudio de mercado' : 'Market research',
			description:
				language === 'es'
					? 'Tenemos como prioridad dominar las particularidades de tu industria, antes de ofrecerle solución a tus problemas.'
					: 'Our priority is to have an in-depth understanding of the particularities of your industry, before offering solutions to your problems.',
			image: '/assets/illustrations/keys/market-research.svg'
		},
		{
			title: language === 'es' ? 'Ingeniería aplicada' : 'Applied engineering',
			description:
				language === 'es'
					? 'Somos capaces de incrementar tu productividad, automatizando procesos y reduciendo errores.'
					: 'We are able to increase your productivity, automating processes and reducing errors.',
			image: '/assets/illustrations/keys/applied-engineering.svg'
		},
		{
			title: language === 'es' ? 'Desarrollo modular' : 'Modular development',
			description:
				language === 'es'
					? 'Colaboramos por etapas, para implementaciones con mayor rapidez y beneficios oportunos '
					: 'We collaborate in stages, for faster implementations and timely benefits.',
			image: '/assets/illustrations/keys/modular-development.svg'
		}
	];

	const buildTitle = () => {
		return (
			<Title
				a-join="animate__fadeIn animate__fadeOut"
				a-join-loop="false"
				className="col-12"
				title={
					{
						es: 'Somos tu',
						en: 'We are your'
					}[language]
				}
				accentTitle={
					{
						es: 'mejor opción',
						en: 'best option'
					}[language]
				}
				text={
					{
						es:
							'Nuestro equipo bilingüe y remoto está listo para integrarse a tu proyecto hoy mismo, con flexibilidad en la zona horaria que más te convenga.',
						en:
							'Our bilingual and remote team is ready to join your project today, with flexibility in the time zone that suits you best.'
					}[language]
				}
			/>
		);
	};

	return (
		<section id="keys" className=" d-flex flex-center-center bg-light">
			<div className="container">
				<div className="row gy-4 py-content">
					{buildTitle()}
					<div className="col-12">
						<div className="row g-4">
							{services.map((service) => {
								return (
									<KeysCard
										className=""
										key={service.title}
										title={service.title}
										description={service.description}
										icon={service.image}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
