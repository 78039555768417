import React from 'react';

import './index.scss';

export const ServiceCard = ({ index, service, language, isSelected, onClick }) => {
	const handleClick = () => {
		onClick(service.id);
	};

	return (
		<div
			a-join="animate__fadeInLeft animate__fadeOutLeft"
			a-join-loop="false"
			className={[
				'service text-light',
				'col-12 col-md-4 col-unit-lg-5',
				index === 4 ? 'col-sm-12' : 'col-sm-6',
				index === 3 || index === 4 ? 'col-md-6' : 'col-md-4'
			].join(' ')}
		>
			<button
				className={[
					'service_button h-100 w-100 btn btn-dark-t position-relative d-flex flex-evenly-center flex-sm-center flex-sm-column rounded-2 p-2 p-sm-3',
					isSelected ? 'is-visible' : ' '
				].join(' ')}
				onClick={handleClick}
			>
				<div className="m-0 m-sm-2">
					<img className="service_image" src={service.image} alt="Service" />
				</div>
				<div>
					<h5 className="mb-0">{service.title[language]}</h5>
				</div>
				<div className="service_description w-100 h-100 position-absolute d-flex flex-center-center p-2 p-sm-3">
					<p className="">{service.description[language]}</p>
				</div>
			</button>
		</div>
	);
};
